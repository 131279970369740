import { HttpErrorResponse, HttpEventType, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, tap } from 'rxjs';
import { SnackBarService } from '../components/snack-bar/snackBar.service';

export const errorHandlingInterceptor: HttpInterceptorFn = (req, next) => {

  //referencia al servicio de snackbar
  const snackService = inject(SnackBarService)

  return next(req).pipe(

    tap(event => {
      if (event.type === HttpEventType.Response) {

        //mostramos snack de suceso solo si la operación es de actualización de data, esto para no mostrar snack con solicitudes de información
        if (
          (req.method == 'POST' || req.method == 'PUT' || req.method == 'DELETE' || req.method == 'PATCH')
          && (event.status >= 200 && event.status <= 299)
        ) {

          let msg = "";

          switch (req.method) {
            case 'POST':
              msg = "El registro ha sido creado con éxito."
              break;
            case 'PUT':
              msg = "El registro ha sido actualizado con éxito."
              break;
            case 'PATCH':
              msg = "El registro ha sido actualizado con éxito."
              break;
            case 'DELETE':
              msg = "El registro ha sido eliminado con éxito."
              break;

          }
          snackService.displaySnackBar(
            'success',
            'Registro Actualizado',
            msg
          );
        }
      }
    }),


    catchError((err: HttpErrorResponse) => {

      console.log(err)
      if (err) {
        switch (err.status) {
          case 409:
            //en caso de problema de validación mostramos snack de error
            snackService.displaySnackBar(
              'error',
              'Error',
              err.error.error
            )
            break;
          case 400:
            //en caso de bad request, mostramos mensaje generico porque hay problema al completar el DTO enviado
            snackService.displaySnackBar(
              'error',
              'Error',
              'Hubo un problema al iniciar la operación, por favor comuníquese con soporte'
            )
            break;
          // en case de otro error mostramos snack pero con descripcion generica para no mostrar 'internal server error'
          default:
            snackService.displaySnackBar(
              'error',
              'Error',
              'Hubo un problema al completar la operación, por favor comuníquese con soporte'
            )
            break;
        }
      }
      throw err;
    }


    ));
};

