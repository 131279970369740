import { ApplicationConfig, provideZoneChangeDetection, APP_INITIALIZER } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideHttpClient, withInterceptors } from '@angular/common/http';

import { routes } from './app.routes';
import { InitService } from './services/init/init.service';
import { Company } from './_models/company.model';
import { Observable } from 'rxjs';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { authInterceptor } from './_interceptors/auth.interceptor';
import { errorHandlingInterceptor } from './_interceptors/error-handling.interceptor';

export function initializeApp(initService: InitService): () => Observable<Company> {
  return () => initService.getExternalReferences();
}


export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideHttpClient(withInterceptors([authInterceptor, errorHandlingInterceptor]),),
    InitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [InitService],
      multi: true
    }, provideAnimationsAsync('noop')
  ]
};
